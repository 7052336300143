/*
 *  inlineDisqussions
 *  By Tsachi Shlidor ( @shlidor )
 *  Inspired by http://mystrd.at/articles/multiple-disqus-threads-on-one-page/
 *
 *  USAGE:
 *
 *       disqus_shortname = 'your_disqus_shortname';
 *       $(document).ready(function() {
 *         $("p").inlineDisqussions(options);
 *       });
 *
 *  See https://github.com/tsi/inlineDisqussions for more info.
 */

// Disqus global vars.
var disqus_shortname;
var disqus_identifier;
var disqus_url;

(function ($) {

    var settings = {};

    $.fn.extend({
        inlineDisqussions: function (options) {

            // Set up defaults
            var defaults = {
                identifier: 'disqussion',
                displayCount: true,
                highlighted: false,
                position: 'right',
                background: 'white',
                maxWidth: 9999
            };

            // Overwrite default options with user provided ones.
            settings = $.extend({}, defaults, options);

            // Attach a discussion to each paragraph.
            $(this).each(function (i) {
                if (!$(this).hasClass('init')) {
                    disqussionNotesHandler(i, $(this));
                }
            });

            // Display comments count.
            if (settings.displayCount) {
                loadDisqusCounter();
            }
        }
    });

    var disqussionNotesHandler = function (i, node) {

        var identifier;
        // You can force a specific identifier by adding an attribute to the paragraph.
        if (node.attr('data-disqus-identifier')) {
            identifier = node.attr('data-disqus-identifier');
        }
        else {
            while ($('[data-disqus-identifier="' + window.location.pathname + settings.identifier + '-' + i + '"]').length > 0) {
                i++;
            }
            identifier = window.location.pathname + settings.identifier + '-' + i;
        }

        node.addClass('init');

        // Create the discussion note.
        var cls = settings.highlighted ? 'disqussion-link disqussion-highlight' : 'disqussion-link';
        var a = $('<a class="' + cls + '" />')
            .attr('href', node.data('disqus-href') + '#disqus_thread')
            .attr('data-disqus-identifier', identifier)
            .attr('data-disqus-url', node.data('disqus-href'))
            .attr('data-disqus-position', settings.position)
            .text('оставить комментарий')
            .wrap('<div class="disqussion" />')
            .parent()
            .appendTo(node);

        node.attr('data-disqus-identifier', identifier).mouseover(function () {
            a.addClass("hovered");
        }).mouseout(function () {
            a.removeClass("hovered");
        });

        // Load the relative discussion.
        a.delegate('a.disqussion-link', "click", function (e) {
            e.preventDefault();

            if ($(this).is('.active')) {
                e.stopPropagation();
                hideDisqussion();
            }
            else {
                loadDisqus($(this));
            }

        });

    };

    var loadDisqus = function (source) {

        disqus_identifier = source.attr('data-disqus-identifier');
        disqus_url = source.attr('data-disqus-url');

        $('#disqus_thread').remove();

        source.parent().append('<div id="disqus_thread"/>');

        if (window.DISQUS) {
            // If Disqus exists, call it's reset method with new parameters.
            DISQUS.reset({
                reload: true,
                config: function () {
                    this.page.identifier = disqus_identifier;
                    this.page.url = disqus_url;
                }
            });

        } else {

            // Append the Disqus embed script to <head>.
            var s = document.createElement('script');
            s.type = 'text/javascript';
            s.async = true;
            s.src = '//' + disqus_shortname + '.disqus.com/embed.js';
            $('head').append(s);

        }

        // Add 'active' class.
        $('a.disqussion-link, a.main-disqussion-link').removeClass('active').filter(source).addClass('active');

        // Highlight
        if (source.is('.disqussion-highlight')) {
            highlightDisqussion(disqus_identifier);
        }

    };

    var loadDisqusCounter = function () {

        // Append the Disqus count script to <head>.
        if ($('script[src*="disqus.com/count.js"]').length) {
            $('script[src*="disqus.com/count.js"]').remove();
        }

        var s = document.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = '//' + disqus_shortname + '.disqus.com/count.js';

        $('head').append(s);

    };

    var hideDisqussion = function () {

        $('#disqus_thread').stop().fadeOut('fast');
        $('a.disqussion-link').removeClass('active');

        // settings.highlighted
        $('#disqussions_overlay').fadeOut('fast');
        $('body').removeClass('disqussion-highlight');
        $('[data-disqus-identifier]').removeClass('disqussion-highlighted');

    };

    var highlightDisqussion = function (identifier) {

        $('body').addClass('disqussion-highlight');
        $('#disqussions_overlay').fadeIn('fast');
        $('[data-disqus-identifier]')
            .removeClass('disqussion-highlighted')
            .filter('[data-disqus-identifier="' + identifier + '"]:not(".disqussion-link")')
            .addClass('disqussion-highlighted');

    };

})(jQuery);
