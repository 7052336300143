/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================
 * ================================================== */

(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // Home page
        'home': {
            finalize: function () {

            }
        },
        // All pages
        'common': {
            init: function () {

            },
            finalize: function () {

                var didScroll;
                var lastScrollTop = 0;
                var delta = 5;


                if (!$('body.page:not(.home)').length) {
                    $(window).scroll(function(event){
                        didScroll = true;
                    });
                    setInterval(function() {
                        if (didScroll) {
                            hasScrolled();
                            didScroll = false;
                        }
                    }, 250);
                }

                function hasScrolled() {
                    var st = $(this).scrollTop();

                    if(Math.abs(lastScrollTop - st) <= delta)
                        return;

                    if (st > lastScrollTop){
                        // Scroll Down
                        $('.footer').fadeOut();
                    } else {
                        if (st + $(window).height() < $(document).height()) {
                            $('.footer').fadeIn();
                        }
                    }
                    lastScrollTop = st;
                }

                function SelectContent(text) {
                    // Create a "hidden" input
                    var aux = document.createElement("input");

                    aux.setAttribute("value", text);
                    // Append it to the body
                    document.body.appendChild(aux);
                    // Highlight its content
                    aux.select();
                    // Copy the highlighted text
                    document.execCommand("copy");
                    // Remove it from the body
                    document.body.removeChild(aux);
                }

                $.fn.almComplete = function(){

                    if (FB) {
                        FB.XFBML.parse();
                    }

                    $('.journal__disqus').inlineDisqussions();
                };

                $('.journal').on('click', '.copy_link', function (e) {
                    e.preventDefault();

                    if ($(this).hasClass('copied')) return;

                    SelectContent( $(this).attr('title'));
                    $(this).addClass('copied')
                });

                $('.single .journal__disqus').inlineDisqussions();

                $('.header__search-content input[type=submit]').click(function (e) {
                    if ($(window).width() <= 996) {
                        $container = $('.header__search-content');
                        if ($container.hasClass('active')) {

                        } else {
                            e.preventDefault();
                            $container.addClass('active');
                        }
                    }
                });

                $('.header__search-content input[type=reset]').click(function (e) {
                    if ($(window).width() <= 996) {
                        $('.header__search-content').removeClass('active');
                    }
                });

                $('.header__nav').on('mouseenter hover click', 'li', function (e) {

                    var $headBtm = $('.header__bottom');
                    $headBtm.find('.nav__item').hide().removeClass('active');

                    if ($(e.currentTarget).hasClass('toggle-nav')) {

                        var rel = $(this).children().attr('rel');

                        if (rel) {

                            $headBtm.find('.' + rel).show();

                            $headBtm.addClass('active');

                            if (e.type == 'click') {

                                //$(e.currentTarget).parent().find('.active').removeClass('active');
                                //$headBtm.find('.' + rel).toggleClass('active');
                                //$(e.currentTarget).addClass('active');
                            }
                        }

                        $(window).resize();
                    } else {
                        $headBtm.removeClass('active');
                    }

                    if (e.type != 'click') {
                        $(e.currentTarget).closest('ul').find('.js-hover').removeClass('js-hover');
                        //$(e.currentTarget).addClass('js-hover');
                    }

                });

                $('header').on('mouseleave', function (e) {
                    e.preventDefault();

                    var $headBtm = $('.header__bottom'),
                        $nav = $headBtm.find('.nav__item'),
                        $navAct = $nav.filter('.active');

                    if (!$navAct.length) {
                        $headBtm.find('.nav__item').hide();
                        $headBtm.removeClass('active');
                        $('.toggle-nav.active').removeClass('active');
                        //$('.toggle-nav.js-hover').removeClass('js-hover');
                    }
                });

                resize();
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    function resize() {

        var $journalBlock = $('.journal-right');
        if ($journalBlock.length) {
            var $rightHeight = Math.floor($(window).height()  - $('header').height());

            $journalBlock.height($rightHeight);
            $journalBlock.children().height($rightHeight / 3);
        }
    }

    // Load Events
    $(document).ready(UTIL.loadEvents);
    $(window).resize(resize);

})(jQuery); // Fully reference jQuery after this point.
